// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-anixe-hellas-privacy-tsx": () => import("./../../../src/pages/anixe-hellas-privacy.tsx" /* webpackChunkName: "component---src-pages-anixe-hellas-privacy-tsx" */),
  "component---src-pages-blog-tags-tsx": () => import("./../../../src/pages/blog-tags.tsx" /* webpackChunkName: "component---src-pages-blog-tags-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-demo-form-tsx": () => import("./../../../src/pages/demo-form.tsx" /* webpackChunkName: "component---src-pages-demo-form-tsx" */),
  "component---src-pages-estimate-project-tsx": () => import("./../../../src/pages/estimate-project.tsx" /* webpackChunkName: "component---src-pages-estimate-project-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-contact-form-tsx": () => import("./../../../src/pages/jobs-contact-form.tsx" /* webpackChunkName: "component---src-pages-jobs-contact-form-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-professional-services-tsx": () => import("./../../../src/pages/professional-services.tsx" /* webpackChunkName: "component---src-pages-professional-services-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-travel-industry-solutions-tsx": () => import("./../../../src/pages/travel-industry-solutions.tsx" /* webpackChunkName: "component---src-pages-travel-industry-solutions-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-product-page-template-tsx": () => import("./../../../src/templates/ProductPageTemplate.tsx" /* webpackChunkName: "component---src-templates-product-page-template-tsx" */),
  "component---src-templates-professional-services-subpage-template-tsx": () => import("./../../../src/templates/ProfessionalServicesSubpageTemplate.tsx" /* webpackChunkName: "component---src-templates-professional-services-subpage-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/TagTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */)
}

